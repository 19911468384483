/* Bio Sans Fonts */
@font-face {
  font-family: 'Bio Sans Bold Italic';
  src: url('./assets/fonts/Flat-it - Bio Sans Bold Italic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Bio Sans Bold';
  src: url('./assets/fonts/Flat-it - Bio Sans Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Bio Sans ExtraBold Italic';
  src: url('./assets/fonts/Flat-it - Bio Sans ExtraBold Italic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Bio Sans ExtraLight Italic';
  src: url('./assets/fonts/Flat-it - Bio Sans ExtraLight Italic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Bio Sans Italic';
  src: url('./assets/fonts/Flat-it - Bio Sans Italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Bio Sans Light Italic';
  src: url('./assets/fonts/Flat-it - Bio Sans Light Italic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Bio Sans SemiBold Italic';
  src: url('./assets/fonts/Flat-it - Bio Sans SemiBold Italic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Bio Sans ExtraBold';
  src: url('./assets/fonts/Flat-it - BioSans-ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Bio Sans ExtraLight';
  src: url('./assets/fonts/Flat-it - BioSans-ExtraLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Bio Sans Light';
  src: url('./assets/fonts/Flat-it - BioSans-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Bio Sans Regular';
  src: url('./assets/fonts/Flat-it - BioSans-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bio Sans SemiBold';
  src: url('./assets/fonts/Flat-it - BioSans-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

/* Diazo Fonts */
@font-face {
  font-family: 'Diazo Cond';
  src: url('./assets/fonts/fonnts.com-Diazo_MVB_Cond.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Diazo Cond Black';
  src: url('./assets/fonts/fonnts.com-Diazo_MVB_Cond_Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Diazo Cond Bold';
  src: url('./assets/fonts/fonnts.com-Diazo_MVB_Cond_Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Diazo Cond Light';
  src: url('./assets/fonts/fonnts.com-Diazo_MVB_Cond_Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Diazo Ex Cond';
  src: url('./assets/fonts/fonnts.com-Diazo_MVB_Ex_Cond.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Diazo Ex Cond Black';
  src: url('./assets/fonts/fonnts.com-Diazo_MVB_Ex_Cond_Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Diazo Ex Cond Bold';
  src: url('./assets/fonts/fonnts.com-Diazo_MVB_Ex_Cond_Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Diazo Ex Cond Light';
  src: url('./assets/fonts/fonnts.com-Diazo_MVB_Ex_Cond_Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}